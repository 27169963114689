import { useState } from "react";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import InputSearch from "../../../components/forms/InputSearch";
import { authContext } from "../../../context/AuthContext";
import { Logout } from "../../../helpers/Logout";
import { clientData, fetchClients } from "../../../slices/clients/Index";
import {
  fetchProjects,
  projectData,
  setProjects,
} from "../../../slices/projects/Index";
import { fetchMe, meState } from "../../../slices/users/Index";
import { DownloadExcel } from "../../../utils/DownloadExcel";

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { URLs } = context;
  const {
    URL_LOGOUT,
    URL_ME,
    URL_INDEX_TYPES,
    URL_INDEX_PROJECTS,
    URL_INDEX_CLIENT,
  } = URLs;

  const _projects = useSelector(projectData);
  const _user = useSelector(meState);
  const _clients = useSelector(clientData);
  const me = _user.me;
  const auth = _user.auth;
  const projects = _projects.projects;
  const projects_eraser = _projects.projects_eraser;
  const projects_template = _projects.projects_template;
  const clients = _clients.clients;

  const [search, setSearch] = useState("");

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "download-excel": {

      } break;
      case "menu-btn":
        {
          if (
            document.getElementById("sidebar").classList.contains("active-nav")
          ) {
            document.getElementById("sidebar").classList.remove("active-nav");
            document
              .getElementById("container")
              .classList.remove("active-cont");
          } else {
            document.getElementById("sidebar").classList.add("active-nav");
            document.getElementById("container").classList.add("active-cont");
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          //
          let params = {
            url: URL_INDEX_PROJECTS,
            search: e.target.value,
            status: 1,
          };

          if (
            e.target.value.length > 0 &&
            e.target.value.length > search.length
          ) {
            dispatch(fetchProjects(params));

            setSearch(e.target.value);
          }

          if (e.target.value.trim() == "") {
            params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };

            dispatch(fetchProjects(params));
          }

          //Insert value form
          setSearch(e.target.value);
        }
        break;
    }
  };

  useEffect(() => {
    if (!me) {
      let params = {
        url: URL_ME,
      };
      dispatch(fetchMe(params));
    }
  }, []);

  useEffect(() => {
    if (!projects) {
      let params = {
        url: URL_INDEX_PROJECTS,
        status: 1,
        pagination: 100
      };
      dispatch(fetchProjects(params));
    }
  }, []);

  useEffect(() => {
    if (!projects_template) {
      let params = {
        url: URL_INDEX_PROJECTS,
        status: 4,
      };
      dispatch(fetchProjects(params));
    }
  }, []);

  useEffect(() => {
    if (!projects_eraser) {
      let params = {
        url: URL_INDEX_PROJECTS,
        status: 3,
        pagination: 100
      };
      dispatch(fetchProjects(params));
    }
  }, []);

  useEffect(() => {
    if (!clients) {
      let params = {
        url: URL_INDEX_CLIENT,
        status: 1,
      };
      dispatch(fetchClients(params));
    }
  }, []);

  //console.log(projects);
  return (
    <div className="">
      {me && projects && projects_eraser && projects_template ? (
        <>
          <nav
            class="navbar top-navbar p-4 bg-azulOscuro"
            style={{ zIndex: 4, top: 0 }}
          >
            <div class=" d-flex align-items-center">
              <label class="me-4 btn">
                <img src="/assets/img/icons/icon-menu.svg" />
                <button
                  className="d-none"
                  name="menu-btn"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
              <a href="#">
                <img src="/assets/img/logo-chimpance.svg" />
              </a>
            </div>
          </nav>

          <div
            class="side-navbar justify-content-between flex-wrap flex-column ps-3  pe-1"
            id="sidebar"
            style={{ top: 0, width: "280px" }}
          >
            <ul class="nav  overflow-auto m-0 pe-2 border-0 h-100">
              <li className="list-group-item mb-3">
                <h6>PLANTILLAS</h6>
              </li>

              <li className="list-group-item d-block w-100">
                <NavLink
                  to={"/dashboard/new/" + null}
                  className={(navData) =>
                    navData.isActive
                      ? "nav-link fs-6 fc-celeste text-nowrap d-flex mb-3"
                      : "text-dark fs-6 text-nowrap d-flex mb-3"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                    className="m-0"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>{" "}
                  PROPUESTA
                </NavLink>
              </li>

              {projects_template.data.map((project) => (
                <NavLink
                  to={"/dashboard/template/" + project.id}
                  className={(navData) =>
                    navData.isActive
                      ? "nav-link fc-celeste text-nowrap d-flex mb-3 ms-2 fs-6"
                      : "text-dark text-nowrap d-flex mb-3 ms-2 fs-6"
                  }
                >
                  {project.template}
                </NavLink>
              ))}

              <li class="mt-3 mb-3 list-group-item d-block w-100">
                <h6>PROPUESTAS</h6>
              </li>

              <li className="list-group-item text-dark text-nowrap d-flex mb-1 w-100">
                <InputSearch
                  //Eventos
                  onChange={onChange}
                  //search
                  label={""}
                  name={"search"}
                  type={"text"}
                  value={search}
                  placeholder={"Buscar propuesta"}
                  //search
                  onClick={""}
                  validate={{ msg: "", validate: false }}
                />
              </li>

              <li
                className="overflow-auto mb-3 w-100"
                style={{ height: "400px" }}
              >
                {projects.data.map((project) => (
                  <NavLink
                    to={"/dashboard/project/" + project.id}
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link fc-celeste d-flex mb-3 fs-6"
                        : "text-dark d-flex mb-3 fs-6"
                    }
                    style={{ width: "200px" }}
                  >
                    <div class="fc-celeste">
                      {project.clients.business_name + " | " + project.title}
                    </div>
                    <span className="ms-2">
                      <svg
                        width="16"
                        height="19"
                        viewBox="0 0 16 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.625 17.1615H1.875C1.64294 17.1615 1.42038 17.059 1.25628 16.8765C1.09219 16.694 1 16.4465 1 16.1885V6.45866C1 6.20061 1.09219 5.95313 1.25628 5.77066C1.42038 5.58819 1.64294 5.48568 1.875 5.48568H10.625C10.8571 5.48568 11.0796 5.58819 11.2437 5.77066C11.4078 5.95313 11.5 6.20061 11.5 6.45866V16.1885C11.5 16.4465 11.4078 16.694 11.2437 16.8765C11.0796 17.059 10.8571 17.1615 10.625 17.1615Z"
                          fill="#00214B"
                        />
                        <path
                          d="M13.25 13.2695H14.125C14.3571 13.2695 14.5796 13.167 14.7437 12.9846C14.9078 12.8021 15 12.5546 15 12.2966V2.56673C15 2.30868 14.9078 2.0612 14.7437 1.87873C14.5796 1.69626 14.3571 1.59375 14.125 1.59375H5.375C5.14294 1.59375 4.92038 1.69626 4.75628 1.87873C4.59219 2.0612 4.5 2.30868 4.5 2.56673V3.53971M10.625 17.1615H1.875C1.64294 17.1615 1.42038 17.059 1.25628 16.8765C1.09219 16.694 1 16.4465 1 16.1885V6.45866C1 6.20061 1.09219 5.95313 1.25628 5.77066C1.42038 5.58819 1.64294 5.48568 1.875 5.48568H10.625C10.8571 5.48568 11.0796 5.58819 11.2437 5.77066C11.4078 5.95313 11.5 6.20061 11.5 6.45866V16.1885C11.5 16.4465 11.4078 16.694 11.2437 16.8765C11.0796 17.059 10.8571 17.1615 10.625 17.1615Z"
                          stroke="#00214B"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                ))}
              </li>

              <li class="list-group-item d-block w-100 mb-3">
                <h6>BORRADOR</h6>
              </li>

              <li
                className="list-group-item overflow-auto w-100 me-2 mb-3"
                style={{ height: "400px" }}
              >
                {projects_eraser.data.map((project) => (
                  <NavLink
                    to={"/dashboard/project/" + project.id}
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link fc-celeste d-flex mb-3 fs-6"
                        : "text-dark d-flex mb-3 fs-6 "
                    }
                    style={{ width: "200px" }}
                  >
                    <div class="fc-celeste">
                      {project.clients
                        ? project.clients.business_name
                        : "Sin cliente"}{" "}
                      {" | " + project.title}
                    </div>
                    <span className="ms-2">
                      <svg
                        width="16"
                        height="19"
                        viewBox="0 0 16 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.625 17.1615H1.875C1.64294 17.1615 1.42038 17.059 1.25628 16.8765C1.09219 16.694 1 16.4465 1 16.1885V6.45866C1 6.20061 1.09219 5.95313 1.25628 5.77066C1.42038 5.58819 1.64294 5.48568 1.875 5.48568H10.625C10.8571 5.48568 11.0796 5.58819 11.2437 5.77066C11.4078 5.95313 11.5 6.20061 11.5 6.45866V16.1885C11.5 16.4465 11.4078 16.694 11.2437 16.8765C11.0796 17.059 10.8571 17.1615 10.625 17.1615Z"
                          fill="#00214B"
                        />
                        <path
                          d="M13.25 13.2695H14.125C14.3571 13.2695 14.5796 13.167 14.7437 12.9846C14.9078 12.8021 15 12.5546 15 12.2966V2.56673C15 2.30868 14.9078 2.0612 14.7437 1.87873C14.5796 1.69626 14.3571 1.59375 14.125 1.59375H5.375C5.14294 1.59375 4.92038 1.69626 4.75628 1.87873C4.59219 2.0612 4.5 2.30868 4.5 2.56673V3.53971M10.625 17.1615H1.875C1.64294 17.1615 1.42038 17.059 1.25628 16.8765C1.09219 16.694 1 16.4465 1 16.1885V6.45866C1 6.20061 1.09219 5.95313 1.25628 5.77066C1.42038 5.58819 1.64294 5.48568 1.875 5.48568H10.625C10.8571 5.48568 11.0796 5.58819 11.2437 5.77066C11.4078 5.95313 11.5 6.20061 11.5 6.45866V16.1885C11.5 16.4465 11.4078 16.694 11.2437 16.8765C11.0796 17.059 10.8571 17.1615 10.625 17.1615Z"
                          stroke="#00214B"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </NavLink>
                ))}
              </li>

              <li class="list-group-item mb-2 list-group-item w-100">
                <NavLink
                  to={"/dashboard/clients"}
                  className={(navData) =>
                    navData.isActive ? "fc-celeste" : "text-dark"
                  }
                >
                  CLIENTES
                </NavLink>
              </li>

              <li class="mb-2 list-group-item w-100">
                <NavLink
                  to={"/dashboard/faqs"}
                  className={(navData) =>
                    navData.isActive ? "fc-celeste" : "text-dark"
                  }
                >
                  FAQs
                </NavLink>
              </li>

              <li class="mb-2 list-group-item w-100">
                <NavLink
                  to={"/dashboard/services"}
                  className={(navData) =>
                    navData.isActive ? "fc-celeste" : "text-dark"
                  }
                >
                  SERVICIOS
                </NavLink>
              </li>

              <li class="mb-2 list-group-item w-100">
                <NavLink
                  to={"/dashboard/features"}
                  className={(navData) =>
                    navData.isActive ? "fc-celeste" : "text-dark"
                  }
                >
                  CARACTERÍSTICAS
                </NavLink>
              </li>

              <li className="mb-4 list-group-item w-100"> 
                <span class="nav-link h4 w-100 mb-5 p-0">
                  <label role="button" className="nav-link p-0 m-0 text-dark fw-normal p-0">
                    DESCARGAR CONSULTAS
                    <button
                      className="d-none"
                      onClick={(event) => DownloadExcel()}
                    ></button>
                  </label>
                </span>
              </li>

              <li className="list-group-item w-100">
                <span class="nav-link h4 w-100 mb-5 p-0">
                  <label role="button" className="nav-link p-0 m-0 fc-celeste">
                    Cerrar Sesión
                    <button
                      className="d-none"
                      onClick={(event) =>
                        Logout(navigate, dispatch, URL_LOGOUT)
                      }
                    ></button>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </>
      ) : (
        ""
      )}
      {!me ? (
        auth == "authenticated" ? (
          <div></div>
        ) : auth == false ? (
          navigate("/login")
        ) : (
          ""
        )
      ) : (
        <div class="my-container" id="container">
          {children}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
