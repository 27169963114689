import clientAxios from "../config/ClientAxios";

export const DownloadExcel = async () => {
  const response = await clientAxios.get(
    "https://propuestas.chimpance.digital/api/api/mail/blog/9w04m9LAMhWQ72M6J7Obs004EOeZVz5QgWETlhEwYRhR3e33jTzYdhUtuMBVbuluVZrEQzVFaXuOHRXyXXP4btpMV0c5iiEdt1LmWWlXrK852UUIg3AhXohmwsNe5cydYB?type=contact"
  );
  // Datos del archivo (cada fila es un array)

  let datos = [["company position", "consultas", "country", "email", "created_at", "name", "phone", "subject", "mesagge"]];
  
  response.data.data.map((consulta) => {
    datos.push([
        consulta.company_position, 
        consulta.consultas, 
        consulta.country, 
        consulta.email, 
        consulta.created_at, 
        consulta.name, 
        consulta.phone, 
        consulta.subject,
        consulta.mesagge,
    ])
  });

  // Convertir array en texto CSV
  const csvContent = datos.map((row) => row.join(",")).join("\n");

  // Crear un Blob con el contenido CSV
  const blob = new Blob([csvContent], { type: "application/vnd.ms-excel" });

  // Crear enlace de descarga
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = "archivo.xls";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
