import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../components/alerts/Alerts";
import Spinner from "../../../components/loads/Spinner";
import clientAxios from "../../../config/ClientAxios";
import { authContext } from "../../../context/AuthContext";
import ApiRest from "../../../hooks/useRest";
import { clientData } from "../../../slices/clients/Index";
import {
  faqData,
  fetchFaqs,
  fetchFaqsProject,
} from "../../../slices/faqs/Index";
import {
  featureData,
  fetchFeaturesTypes,
} from "../../../slices/features/Index";
import {
  fetchMethodologies,
  methodologyData,
} from "../../../slices/methodologies/Index";
import {
  fetchPayments,
  paymentTypeData,
} from "../../../slices/payments_types/Index";
import { fetchProjects } from "../../../slices/projects/Index";
import { fetchRegions, regionData } from "../../../slices/regions/Index";
import { AttachClient } from "../../../utils/AttachClient";
import { AttachPromotion } from "../../../utils/AttachPromotion";
import { AttachProject } from "../../../utils/AttachProyect";
import { ConcatPrice } from "../../../utils/ConcatPrices";
import { USD } from "../../../utils/Price";
import { Validate } from "../../../utils/ValidateForms";
import ListFaqs from "../faqs/list/ListFaqs";
import ModalFaqs from "../faqs/modals/ModalFaqs";
import ModalFeature from "../features/modals/ModalFeature";
import CardPrice from "../prices/cards/CardPrice";
import ModalPrice from "../prices/modals/ModalPrice";
import CardService from "../services/card/CardService";
import ModalService from "../services/modals/ModalService";
import FormGeneral from "./forms/FormGeneral";
import FormMethodology from "./forms/FormMethodology";
import FormPayments from "./forms/FormPayments";
import FormPromotion from "./forms/FormPromotion";
import FormRegion from "./forms/FormRegion";
import { OrderFeature } from "../../../utils/orderFeatures";
import ModalListServices from "../services/modals/ModalListServices";
import Pagination from "../../../components/paginations/Pagination";

const Project = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(authContext);
  const { URLs, FORM, VALIDATOR, HARDDATA } = context;

  const {
    URL_INDEX_MSERVICE,
    URL_CREATE_MSERVICE,
    URL_UPDATE_MSERVICE,
    URL_DELETE_MSERVICE,
    URL_DELETE_PRICE,
    URL_INDEX_FAQS,
    URL_CREATE_PROJECT,
    URL_INDEX_PROJECTS,
    URL_UPDATE_PROMOTION,
    URL_INDEX_REGIONS,
    URL_INDEX_FEATURES_TYPES,
    URL_INDEX_PAYMENTS,
    URL_INDEX_METHODOLOGIES,
    URL_UPDATE_PROJECT,
    URL_DELETE_SERVICE,
    URL_DELETE_PROJECT,
    URL_CREATE_CACHE,
    URL_INDEX_CLIENT,
  } = URLs;
  const { FORM_PROJECT, FORM_CLIENTS, FORM_PROMOTIONS } = FORM;
  const {
    VALIDATION_PROJECT,
    VALIDATION_CLIENTS,
    VALIDATION_PROMOTIONS,
    VALIDATION_FEATURES,
    VALIDATION_FAQS,
    VALIDATION_METHODOLOGY,
  } = VALIDATOR;
  const { PAYMENT_CONFIG } = HARDDATA;

  const _faqs = useSelector(faqData);
  const _features = useSelector(featureData);
  const _methodology = useSelector(methodologyData);
  const _payment_types = useSelector(paymentTypeData);
  const _regions = useSelector(regionData);
  const _clients = useSelector(clientData);
  const payment_types = _payment_types.payment_types;
  const methodologies = _methodology.methodologies;
  const features_types = _features.features_types;
  const clients = _clients.search;
  const regions = _regions.regions;
  const faqs = _faqs.faqs_project;
  //const features = _features.features;

  const [orderFeatures, setOrderFeatures] = useState(null);
  const [loadingGuard, setLoadingGuard] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [modalFaqs, setModalFaqs] = useState(false);
  const [modalService, setModalService] = useState(false);
  const [modalPrice, setModalPrice] = useState(false);
  const [modalFeature, setModalFeature] = useState(false);
  const [modalModelService, setModalModelService] = useState(false);
  const [steps, setSteps] = useState(0);
  const [drag, setDrag] = useState(null);

  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_PROJECT)));

  const [formClient, setFormClient] = useState(
    JSON.parse(JSON.stringify(FORM_CLIENTS))
  );
  const [formPromotion, setFormPromotion] = useState(
    JSON.parse(JSON.stringify(FORM_PROMOTIONS))
  );
  const [validate, setValidate] = useState(
    JSON.parse(JSON.stringify(VALIDATION_PROJECT))
  );
  const [validateClient, setValidateClient] = useState(
    JSON.parse(JSON.stringify(VALIDATION_CLIENTS))
  );
  const [validatePromotion, setValidatePromotion] = useState(
    JSON.parse(JSON.stringify(VALIDATION_PROMOTIONS))
  );
  const [validateMethodology, setValidateMethodology] = useState(
    JSON.parse(JSON.stringify(VALIDATION_METHODOLOGY))
  );

  const onClick = async (e, value, index) => {
    e.preventDefault();

    switch (e.target.name) {
      case "save":
        {
          //Delete property
          let validationClient = Validate(formClient);
          setValidateClient(validationClient);

          //Delete property
          let validation = Validate(form);
          setValidate(validation);

          //Validaciones
          if (validationClient.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "400",
              type: "error",
            });
          }

          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "400",
              type: "error",
            });
          }

          let formData = new FormData();

          formData.append("title", form.title[0]);
          formData.append("price", form.price[0]);
          formData.append("time_lapse", form.time_lapse[0]);
          formData.append("methodology_id", form.methodology_id[0]);

          /* 
            set clients 
          */

          if (formClient.client_id[0])
            formData.append("client_id", formClient.client_id[0]);
          formData.append("regions_id", formClient.regions_id[0]);
          formData.append("name", formClient.name[0]);
          formData.append("business_name", formClient.business_name[0]);
          formData.append("email", formClient.email[0]);
          formData.append("status", 1); /* => Status Active */

          if (formPromotion.descont[0])
            formData.append(
              "promo_descont",
              parseFloat(formPromotion.descont[0])
            );
          if (formPromotion.time_duration[0])
            formData.append("time_duration", formPromotion.time_duration[0]);

          /* Payments */

          formData.append("title_option", form.title_option[0]);
          formData.append("description_option", form.description_option[0]);

          if (form.payment_name[0]) {
            formData.append("name_payment_types", form.payment_name[0]);
          }

          if (form.payment_config[0]) {
            formData.append("config_payment_types", form.payment_config[0]);
          }

          form.payment_types_id[0].map((payment, index) => {
            formData.append("payment_types_id[" + index + "]", payment);
          });

          /* Prices */
          if (form.price_basico[0]) {
            formData.append(
              "prices[" + 0 + "][name]",
              form.price_basico[0].name[0]
            );
            formData.append(
              "prices[" + 0 + "][description]",
              form.price_basico[0].description[0]
            );
            formData.append(
              "prices[" + 0 + "][price]",
              form.price_basico[0].price[0]
            );
            formData.append(
              "prices[" + 0 + "][premium]",
              form.price_basico[0].premium[0] ? 1 : 0
            );
            formData.append(
              "prices[" + 0 + "][time_lapse]",
              form.price_basico[0].time_lapse[0]
            );

            if (form.price_basico[0].payment_name[0]) {
              formData.append(
                "prices[" + 0 + "][name_payment_types]",
                form.price_basico[0].payment_name[0]
              );
            }

            if (form.price_basico[0].payment_config[0]) {
              formData.append(
                "prices[" + 0 + "][config_payment_types]",
                form.price_basico[0].payment_config[0]
              );
            }
            form.price_basico[0].payment_types_id[0].map((payment, index) => {
              formData.append(
                "prices[" + 0 + "][payment_types_id][" + index + "]",
                payment
              );
            });
          }
          /* Prices */
          if (form.price_premium[0]) {
            formData.append(
              "prices[" + 1 + "][name]",
              form.price_premium[0].name[0]
            );
            formData.append(
              "prices[" + 1 + "][description]",
              form.price_premium[0].description[0]
            );
            formData.append(
              "prices[" + 1 + "][price]",
              form.price_premium[0].price[0]
            );
            formData.append(
              "prices[" + 1 + "][premium]",
              form.price_premium[0].premium[0] ? 1 : 0
            );
            formData.append(
              "prices[" + 1 + "][time_lapse]",
              form.price_premium[0].time_lapse[0]
            );

            if (form.price_premium[0].payment_name[0]) {
              formData.append(
                "prices[" + 1 + "][name_payment_types]",
                form.price_premium[0].payment_name[0]
              );
            }

            if (form.price_premium[0].payment_config[0]) {
              formData.append(
                "prices[" + 1 + "][config_payment_types]",
                form.price_premium[0].payment_config[0]
              );
            }
            form.price_premium[0].payment_types_id[0].map((payment, index) => {
              formData.append(
                "prices[" + 1 + "][payment_types_id][" + index + "]",
                payment
              );
            });
          }

          //services
          form.services[0].map((service, index) => {
            formData.append("services[" + index + "][name]", service.name[0]);
            formData.append(
              "services[" + index + "][subname]",
              service.subname[0]
            );
            formData.append(
              "services[" + index + "][description]",
              service.description[0]
            );
            formData.append("services[" + index + "][unid]", service.unid[0]);
            formData.append("services[" + index + "][price]", service.price[0]);
            formData.append(
              "services[" + index + "][duration_months]",
              service.duration_months[0]
            );
            formData.append(
              "services[" + index + "][unique]",
              service.unique[0] ? 1 : 0
            );
            formData.append("services[" + index + "][image]", service.image[0]);
          });

          //features
          form.features[0].map((feature, index) => {
            formData.append("features[" + index + "]", feature);
          });

          //faqs
          form.faqs[0].map((faq, index) => {
            formData.append("faqs[" + index + "]", faq);
          });

          const response = await ApiRest({
            url: URL_CREATE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            //cerrar modal
            Alerts({
              msg: "Alert",
              title:
                "https://cotizacion.chimpance.digital/" +
                String(response.data.data.clients.business_name).replace(
                  " ",
                  "-"
                ) +
                "_" +
                String(response.data.data.title).replace(" ", "-"),
              code: "201",
              type: "link",
            });

            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 3,
            };
            dispatch(fetchProjects(params));

            return navigate("/dashboard/project/" + response.data.data.id);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "save_template":
        {
          const setFunction = async (name) => {
            //Delete property
            let validation = Validate(form);
            setValidate(validation);

            //Validaciones
            if (validation.error.validate) {
              return Alerts({
                msg: "Warning",
                title: validation.error.msg,
                code: "201",
                type: "error",
              });
            }

            let formData = new FormData();

            formData.append("title", form.title[0]);
            formData.append("price", form.price[0]);
            formData.append("time_lapse", form.time_lapse[0]);
            formData.append("methodology_id", form.methodology_id[0]);
            formData.append("status", 4); /* Status template */
            formData.append("template", name); /* name template */

            /* Promotions */
            if (formPromotion.descont[0])
              formData.append(
                "promo_descont",
                parseFloat(formPromotion.descont[0])
              );
            if (formPromotion.time_duration[0])
              formData.append("time_duration", formPromotion.time_duration[0]);

            /* Payments */

            formData.append("title_option", form.title_option[0]);
            formData.append("description_option", form.description_option[0]);

            if (form.payment_name[0]) {
              formData.append("name_payment_types", form.payment_name[0]);
            }

            form.payment_types_id[0].map((payment, index) => {
              formData.append("payment_types_id[" + index + "]", payment);
            });

            /* Prices */
            if (form.price_basico[0]) {
              formData.append(
                "prices[" + 0 + "][name]",
                form.price_basico[0].name[0]
              );
              formData.append(
                "prices[" + 0 + "][description]",
                form.price_basico[0].description[0]
              );
              formData.append(
                "prices[" + 0 + "][price]",
                form.price_basico[0].price[0]
              );
              formData.append(
                "prices[" + 0 + "][premium]",
                form.price_basico[0].premium[0] ? 1 : 0
              );
              formData.append(
                "prices[" + 0 + "][time_lapse]",
                form.price_basico[0].time_lapse[0]
              );

              if (form.price_basico[0].payment_name[0]) {
                formData.append(
                  "prices[" + 0 + "][name_payment_types]",
                  form.price_basico[0].payment_name[0]
                );
              }

              if (form.price_basico[0].payment_config[0]) {
                formData.append(
                  "prices[" + 0 + "][config_payment_types]",
                  form.price_basico[0].payment_config[0]
                );
              }

              form.price_basico[0].payment_types_id[0].map((payment, index) => {
                formData.append(
                  "prices[" + 0 + "][payment_types_id][" + index + "]",
                  payment
                );
              });
            }
            /* Prices */
            if (form.price_premium[0]) {
              formData.append(
                "prices[" + 1 + "][name]",
                form.price_premium[0].name[0]
              );
              formData.append(
                "prices[" + 1 + "][description]",
                form.price_premium[0].description[0]
              );
              formData.append(
                "prices[" + 1 + "][price]",
                form.price_premium[0].price[0]
              );
              formData.append(
                "prices[" + 1 + "][premium]",
                form.price_premium[0].premium[0] ? 1 : 0
              );
              formData.append(
                "prices[" + 1 + "][time_lapse]",
                form.price_premium[0].time_lapse[0]
              );

              if (form.price_premium[0].payment_name[0]) {
                formData.append(
                  "prices[" + 1 + "][name_payment_types]",
                  form.price_premium[0].payment_name[0]
                );
              }

              if (form.price_premium[0].payment_config[0]) {
                formData.append(
                  "prices[" + 1 + "][config_payment_types]",
                  form.price_premium[0].payment_config[0]
                );
              }
              form.price_premium[0].payment_types_id[0].map(
                (payment, index) => {
                  formData.append(
                    "prices[" + 1 + "][payment_types_id][" + index + "]",
                    payment
                  );
                }
              );
            }

            /* Services */
            //services
            form.services[0].map((service, index) => {
              formData.append("services[" + index + "][name]", service.name[0]);
              formData.append(
                "services[" + index + "][subname]",
                service.subname[0]
              );
              formData.append(
                "services[" + index + "][description]",
                service.description[0]
              );
              formData.append("services[" + index + "][unid]", service.unid[0]);
              formData.append(
                "services[" + index + "][price]",
                service.price[0]
              );
              formData.append(
                "services[" + index + "][duration_months]",
                service.duration_months[0]
              );
              formData.append(
                "services[" + index + "][unique]",
                service.unique[0] ? 1 : 0
              );
              formData.append(
                "services[" + index + "][image]",
                service.image[0]
              );
            });

            /* Features */
            form.features[0].map((feature, index) => {
              formData.append("features[" + index + "]", feature);
            });

            //faqs
            form.faqs[0].map((faq, index) => {
              formData.append("faqs[" + index + "]", faq);
            });

            const response = await ApiRest({
              url: URL_CREATE_PROJECT,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              let params = {
                url: URL_INDEX_PROJECTS,
                status: 1,
              };
              dispatch(fetchProjects(params));
              params = {
                url: URL_INDEX_PROJECTS,
                status: 3,
              };
              dispatch(fetchProjects(params));
              params = {
                url: URL_INDEX_PROJECTS,
                status: 4,
              };
              dispatch(fetchProjects(params));

              Alerts({
                msg: "Alert",
                title: "Se creo una nueva plantilla",
                code: "201",
                type: "success",
              });

              return navigate("/dashboard/template/" + response.data.data.id);
            }

            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
          };

          return Alerts({
            msg: "Warning",
            title: "",
            code: "201",
            type: "template",
            setFunction: setFunction,
          });
        }
        break;
      case "save_eraser":
        {
          /* Empty Property */
          let validationClient = Validate(formClient);
          setValidateClient(validationClient);

          /* Empty Property */
          let validation = Validate(form);
          setValidate(validation);

          /* Validation */
          if (validationClient.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let formData = new FormData();

          formData.append("title", form.title[0]);
          formData.append("price", form.price[0]);
          formData.append("time_lapse", form.time_lapse[0]);
          formData.append("methodology_id", form.methodology_id[0]);
          /* 
            set clients 
          */

          if (formClient.client_id[0])
            formData.append("client_id", formClient.client_id[0]);
          formData.append("regions_id", formClient.regions_id[0]);
          formData.append("name", formClient.name[0]);
          formData.append("business_name", formClient.business_name[0]);
          formData.append("email", formClient.email[0]);
          formData.append("status", 3); /* => Status Eraser */

          if (formPromotion.descont[0]) {
            formData.append(
              "promo_descont",
              parseFloat(formPromotion.descont[0])
            );
          }

          if (formPromotion.time_duration[0]) {
            formData.append("time_duration", formPromotion.time_duration[0]);
          }

          /* Payments */

          formData.append("title_option", form.title_option[0]);
          formData.append("description_option", form.description_option[0]);

          if (form.payment_name[0]) {
            formData.append("name_payment_types", form.payment_name[0]);
          }

          if (form.payment_config[0]) {
            formData.append("config_payment_types", form.payment_config[0]);
          }

          form.payment_types_id[0].map((payment, index) => {
            formData.append("payment_types_id[" + index + "]", payment);
          });

          /* Prices */
          if (form.price_basico[0]) {
            formData.append(
              "prices[" + 0 + "][name]",
              form.price_basico[0].name[0]
            );
            formData.append(
              "prices[" + 0 + "][description]",
              form.price_basico[0].description[0]
            );
            formData.append(
              "prices[" + 0 + "][price]",
              form.price_basico[0].price[0]
            );
            formData.append(
              "prices[" + 0 + "][premium]",
              form.price_basico[0].premium[0] ? 1 : 0
            );
            formData.append(
              "prices[" + 0 + "][time_lapse]",
              form.price_basico[0].time_lapse[0]
            );

            if (form.price_basico[0].payment_name[0]) {
              formData.append(
                "prices[" + 0 + "][name_payment_types]",
                form.price_basico[0].payment_name[0]
              );
            }

            if (form.price_basico[0].payment_config[0]) {
              formData.append(
                "prices[" + 0 + "][config_payment_types]",
                form.price_basico[0].payment_config[0]
              );
            }

            form.price_basico[0].payment_types_id[0].map((payment, index) => {
              formData.append(
                "prices[" + 0 + "][payment_types_id][" + index + "]",
                payment
              );
            });
          }
          /* Prices */
          if (form.price_premium[0]) {
            formData.append(
              "prices[" + 1 + "][name]",
              form.price_premium[0].name[0]
            );
            formData.append(
              "prices[" + 1 + "][description]",
              form.price_premium[0].description[0]
            );
            formData.append(
              "prices[" + 1 + "][price]",
              form.price_premium[0].price[0]
            );
            formData.append(
              "prices[" + 1 + "][premium]",
              form.price_premium[0].premium[0] ? 1 : 0
            );
            formData.append(
              "prices[" + 1 + "][time_lapse]",
              form.price_premium[0].time_lapse[0]
            );

            if (form.price_premium[0].payment_name[0]) {
              formData.append(
                "prices[" + 1 + "][name_payment_types]",
                form.price_premium[0].payment_name[0]
              );
            }

            if (form.price_premium[0].payment_config[0]) {
              formData.append(
                "prices[" + 1 + "][config_payment_types]",
                form.price_premium[0].payment_config[0]
              );
            }
            form.price_premium[0].payment_types_id[0].map((payment, index) => {
              formData.append(
                "prices[" + 1 + "][payment_types_id][" + index + "]",
                payment
              );
            });
          }

          /* Services */
          //services
          form.services[0].map((service, index) => {
            formData.append("services[" + index + "][name]", service.name[0]);
            formData.append(
              "services[" + index + "][subname]",
              service.subname[0]
            );
            formData.append(
              "services[" + index + "][description]",
              service.description[0]
            );
            formData.append("services[" + index + "][unid]", service.unid[0]);
            formData.append("services[" + index + "][price]", service.price[0]);
            formData.append(
              "services[" + index + "][duration_months]",
              service.duration_months[0]
            );
            formData.append(
              "services[" + index + "][unique]",
              service.unique[0] ? 1 : 0
            );
            formData.append("services[" + index + "][image]", service.image[0]);
          });

          /* Features */
          form.features[0].map((feature, index) => {
            formData.append("features[" + index + "]", feature);
          });

          //faqs
          form.faqs[0].map((faq, index) => {
            formData.append("faqs[" + index + "]", faq);
          });

          const response = await ApiRest({
            url: URL_CREATE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          /* Response */
          if (response.status == 201 || response.status == 200) {
            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 3,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 4,
            };
            dispatch(fetchProjects(params));

            Alerts({
              msg: "Alert",
              title: "Se creo una nueva propuesta en borradores",
              code: "201",
              type: "success",
            });

            return navigate("/dashboard/project/" + response.data.data.id);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "preview":
        {
          //Delete property
          let validationClient = Validate(formClient);
          setValidateClient(validationClient);

          //Delete property
          let validation = Validate(form);
          setValidate(validation);

          //Validaciones
          if (validationClient.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "400",
              type: "error",
            });
          }

          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "400",
              type: "error",
            });
          }

          let formData = new FormData();
          formData.append("storage[title]", form.title[0]);
          formData.append("storage[price]", form.price[0]);
          formData.append("storage[price_solidary]", 0);
          formData.append("storage[time_lapse]", form.time_lapse[0]);
          formData.append("storage[methodology_id]", form.methodology_id[0]);
          formData.append("storage[status]", 1);

          /* Clients */

          formData.append("storage[clients][name]", formClient.name[0]);
          formData.append(
            "storage[clients][business_name]",
            formClient.business_name[0]
          );
          formData.append("storage[clients][email]", formClient.email[0]);
          formData.append(
            "storage[clients][regions_id]",
            formClient.regions_id[0]
          );

          /* Methodology */

          methodologies.map((m) => {
            if (m.id == form.methodology_id[0]) {
              formData.append("storage[methodology][created_at]", m.created_at);
              formData.append(
                "storage[methodology][description]",
                m.description
              );
              formData.append("storage[methodology][id]", m.id);
              formData.append("storage[methodology][name]", m.name);
              formData.append("storage[methodology][status_id]", m.status_id);
            }
          });

          /* Promotions */

          if (formPromotion.descont[0]) {
            formData.append(
              "storage[promotions][descont]",
              formPromotion.descont[0]
            );
            formData.append(
              "storage[promotions][time_duration]",
              formPromotion.time_duration[0]
            );
          }

          /* Payments */

          let array = [];

          payment_types.map((payment) => {
            if (form.payment_types_id[0].indexOf(String(payment.id)) >= 0) {
              array.push(payment);
            }
          });

          if (form.payment_name[0] && form.payment_config[0]) {
            switch (form.payment_config[0]) {
              case "cuota":
                {
                  array.push({
                    name: form.payment_name[0] + " cuotas",
                    description: "A convenir",
                    share: form.payment_name[0],
                  });
                }
                break;
              case "descuento":
                {
                  array.push({
                    name: form.payment_name[0] + "% OFF - PAGO POR ADELANTADO",
                    description: "A convenir",
                    descont: form.payment_name[0],
                  });
                }
                break;
            }
          }

          array.map((payment, index) => {
            formData.append(
              "storage[payments_types][" + index + "][name]",
              payment.name
            );
            formData.append(
              "storage[payments_types][" + index + "][description]",
              payment.description
            );
            formData.append(
              "storage[payments_types][" + index + "][share]",
              payment.share
            );
            formData.append(
              "storage[payments_types][" + index + "][descont]",
              payment.descont
            );
          });

          /* Prices */

          array = [];

          /* Prices */
          if (form.price_basico[0]) {
            formData.append(
              "storage[" + 0 + "][name]",
              form.price_basico[0].name[0]
            );
            formData.append(
              "storage[" + 0 + "][description]",
              form.price_basico[0].description[0]
            );
            formData.append(
              "storage[" + 0 + "][price]",
              form.price_basico[0].price[0]
            );
            formData.append(
              "storage[" + 0 + "][premium]",
              form.price_basico[0].premium[0] ? 1 : 0
            );
          }
          /* Prices */
          if (form.price_premium[0]) {
            formData.append(
              "storage[" + 1 + "][name]",
              form.price_premium[0].name[0]
            );
            formData.append(
              "storage[" + 1 + "][description]",
              form.price_premium[0].description[0]
            );
            formData.append(
              "storage[" + 1 + "][price]",
              form.price_premium[0].price[0]
            );
            formData.append(
              "storage[" + 1 + "][premium]",
              form.price_premium[0].premium[0] ? 1 : 0
            );
          }

          /* Services */

          form.services[0].map((service, index) => {
            formData.append(
              "storage[services][" + index + "][name]",
              service.name[0]
            );
            formData.append(
              "storage[services][" + index + "][subname]",
              service.subname[0]
            );
            formData.append(
              "storage[services][" + index + "][description]",
              service.description[0]
            );
            formData.append(
              "storage[services][" + index + "][unid]",
              service.unid[0]
            );
            formData.append(
              "storage[services][" + index + "][price]",
              service.price[0]
            );
            formData.append(
              "storage[services][" + index + "][duration]",
              service.duration_months[0]
            );
            formData.append(
              "storage[services][" + index + "][unique]",
              service.unique[0]
            );
            formData.append(
              "storage[services][" + index + "][image]",
              service.image[0]
            );
          });

          /* Features */

          array = [];

          features_types.map((type, index) => {
            type.features.map((feat) => {
              if (form.features[0].indexOf(feat.id) >= 0) {
                array.push(feat);
              }
            });
          });

          array.map((features, index) => {
            formData.append(
              "storage[features][" + index + "][description]",
              features.description
            );
          });

          //faqs

          faqs.data.map((faq, index) => {
            array.push(faq);
          });

          array.map((faq, index) => {
            formData.append("storage[faqs][" + index + "]", faq);
          });

          const response = await ApiRest({
            url: URL_CREATE_CACHE,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            /*
                process.env.REACT_APP_API_URL +
                "/Cache?token=" +
                response.data.data.link, 
            */

            //cerrar modal
            return Alerts({
              msg: "Alert",
              title:
                "https://cotizacion.chimpance.digital/" +
                String(formClient.business_name[0]).replace(/ /g, "-") +
                "_" +
                String(form.title[0]).replace(/ /g, "-"),
              code: "201",
              type: "link",
            });
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "delete":
        {
          let formData = new FormData();
          formData.append("id", id);

          const response = await ApiRest({
            url: URL_DELETE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 3,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 4,
            };
            dispatch(fetchProjects(params));

            Alerts({
              msg: "Alert",
              title: "Propuesta eliminada",
              code: "201",
              type: "success",
            });

            return navigate("/dashboard/new/" + null);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "double":
        {
          //Delete property
          let validationClient = Validate(formClient);
          setValidateClient(validationClient);

          //Delete property
          let validation = Validate(form);
          setValidate(validation);

          //Validaciones
          if (validationClient.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "400",
              type: "error",
            });
          }

          if (validation.error.validate) {
            return Alerts({
              msg: "Warning",
              title: validation.error.msg,
              code: "400",
              type: "error",
            });
          }

          let formData = new FormData();

          formData.append("client_id", formClient.id[0]);
          formData.append("title", form.title[0]);
          formData.append("price", form.price[0]);
          formData.append("time_lapse", form.time_lapse[0]);
          formData.append("methodology_id", form.methodology_id[0]);
          formData.append("regions_id", formClient.regions_id[0]);
          formData.append("name", formClient.name[0]);
          formData.append("business_name", formClient.business_name[0]);
          formData.append("email", formClient.email[0]);
          formData.append("status", 1); /* => Status Active */

          if (formPromotion.descont[0])
            formData.append(
              "promo_descont",
              parseFloat(formPromotion.descont[0])
            );
          if (formPromotion.time_duration[0])
            formData.append("time_duration", formPromotion.time_duration[0]);

          /* Payments */

          formData.append("title_option", form.title_option[0]);
          formData.append("description_option", form.description_option[0]);

          if (form.payment_name[0]) {
            formData.append("name_payment_types", form.payment_name[0]);
          }

          form.payment_types_id[0].map((payment, index) => {
            formData.append("payment_types_id[" + index + "]", payment);
          });

          /* Prices */
          if (form.price_basico[0]) {
            formData.append(
              "prices[" + 0 + "][name]",
              form.price_basico[0].name[0]
            );
            formData.append(
              "prices[" + 0 + "][description]",
              form.price_basico[0].description[0]
            );
            formData.append(
              "prices[" + 0 + "][price]",
              form.price_basico[0].price[0]
            );
            formData.append(
              "prices[" + 0 + "][premium]",
              form.price_basico[0].premium[0] ? 1 : 0
            );
            formData.append(
              "prices[" + 0 + "][time_lapse]",
              form.price_basico[0].time_lapse[0]
            );

            if (form.price_basico[0].payment_name[0]) {
              formData.append(
                "prices[" + 0 + "][name_payment_types]",
                form.price_basico[0].payment_name[0]
              );
            }

            if (form.price_basico[0].payment_config[0]) {
              formData.append(
                "prices[" + 0 + "][config_payment_types]",
                form.price_basico[0].payment_config[0]
              );
            }

            form.price_basico[0].payment_types_id[0].map((payment, index) => {
              formData.append(
                "prices[" + 0 + "][payment_types_id][" + index + "]",
                payment
              );
            });
          }
          /* Prices */
          if (form.price_premium[0]) {
            formData.append(
              "prices[" + 1 + "][name]",
              form.price_premium[0].name[0]
            );
            formData.append(
              "prices[" + 1 + "][description]",
              form.price_premium[0].description[0]
            );
            formData.append(
              "prices[" + 1 + "][price]",
              form.price_premium[0].price[0]
            );
            formData.append(
              "prices[" + 1 + "][premium]",
              form.price_premium[0].premium[0] ? 1 : 0
            );
            formData.append(
              "prices[" + 1 + "][time_lapse]",
              form.price_premium[0].time_lapse[0]
            );

            if (form.price_premium[0].payment_name[0]) {
              formData.append(
                "prices[" + 1 + "][name_payment_types]",
                form.price_premium[0].payment_name[0]
              );
            }
            if (form.price_premium[0].payment_config[0]) {
              formData.append(
                "prices[" + 1 + "][config_payment_types]",
                form.price_premium[0].payment_config[0]
              );
            }
            form.price_premium[0].payment_types_id[0].map((payment, index) => {
              formData.append(
                "prices[" + 1 + "][payment_types_id][" + index + "]",
                payment
              );
            });
          }

          //services

          form.services[0].map((service, index) => {
            formData.append("services[" + index + "][name]", service.name[0]);
            formData.append(
              "services[" + index + "][subname]",
              service.subname[0]
            );
            formData.append(
              "services[" + index + "][description]",
              service.description[0]
            );
            formData.append("services[" + index + "][unid]", service.unid[0]);
            formData.append("services[" + index + "][price]", service.price[0]);
            formData.append(
              "services[" + index + "][duration_months]",
              service.duration_months[0]
            );
            formData.append(
              "services[" + index + "][unique]",
              service.unique[0] ? 1 : 0
            );
            formData.append("services[" + index + "][image]", service.image[0]);
          });

          //features
          form.features[0].map((feature, index) => {
            formData.append("features[" + index + "]", feature);
          });

          const response = await ApiRest({
            url: URL_CREATE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            //cerrar modal
            Alerts({
              msg: "Alert",
              title: "Nueva propuesta creada",
              code: "201",
              type: "success",
            });

            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 3,
            };
            dispatch(fetchProjects(params));

            return navigate("/dashboard/project/" + response.data.data.id);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      /* Update Data */
      case "apply":
        {
          let formData = new FormData();
          formData.append("id", id);
          formData.append("title", form.title[0]);
          formData.append("price", form.price[0]);
          formData.append("time_lapse", form.time_lapse[0]);
          formData.append("methodology_id", form.methodology_id[0]);

          if (formClient.id[0]) formData.append("client_id", formClient.id[0]);

          formData.append("regions_id", formClient.regions_id[0]);
          formData.append("name", formClient.name[0]);
          formData.append("business_name", formClient.business_name[0]);
          formData.append("email", formClient.email[0]);
          formData.append("status", 1); /* => Status Active */

          if (formPromotion.descont[0])
            formData.append(
              "promo_descont",
              parseFloat(formPromotion.descont[0])
            );
          if (formPromotion.time_duration[0])
            formData.append("time_duration", formPromotion.time_duration[0]);

          /* Payments */

          formData.append("title_option", form.title_option[0]);
          formData.append("description_option", form.description_option[0]);

          if (form.payment_name[0])
            formData.append("name_payment_types", form.payment_name[0]);

          if (form.payment_config[0])
            formData.append("config_payment_types", form.payment_config[0]);

          form.payment_types_id[0].map((payment, index) => {
            formData.append("payment_types_id[" + index + "]", payment);
          });

          /* features */
          form.features[0].map((feature, index) => {
            formData.append("features[" + index + "]", feature);
          });

          //faqs
          form.faqs[0].map((faq, index) => {
            formData.append("faqs[" + index + "]", faq);
          });

          ApiRest({
            url: URL_UPDATE_PROMOTION,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          const response = await ApiRest({
            url: URL_UPDATE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            //cerrar modal
            Alerts({
              msg: "Alert",
              title: "Propuesta actualizada",
              code: "201",
              type: "success",
            });

            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 3,
            };
            dispatch(fetchProjects(params));

            return navigate("/dashboard/project/" + response.data.data.id);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "apply_eraser":
        {
          let formData = new FormData();

          formData.append("id", id);
          formData.append("title", form.title[0]);
          formData.append("price", form.price[0]);
          formData.append("time_lapse", form.time_lapse[0]);
          formData.append("methodology_id", form.methodology_id[0]);
          formData.append("regions_id", formClient.regions_id[0]);
          formData.append("name", formClient.name[0]);
          formData.append("business_name", formClient.business_name[0]);
          formData.append("email", formClient.email[0]);
          formData.append("status", 3); /* => Status eraser */

          if (formPromotion.descont[0])
            formData.append(
              "promo_descont",
              parseFloat(formPromotion.descont[0])
            );
          if (formPromotion.time_duration[0]) {
            formData.append("time_duration", formPromotion.time_duration[0]);
          }

          /* Payments */
          formData.append("title_option", form.title_option[0]);
          formData.append("description_option", form.description_option[0]);

          if (form.payment_name[0]) {
            formData.append("name_payment_types", form.payment_name[0]);
          }

          if (form.payment_config[0]) {
            formData.append("config_payment_types", form.payment_config[0]);
          }

          form.payment_types_id[0].map((payment, index) => {
            formData.append("payment_types_id[" + index + "]", payment);
          });

          /* features */
          form.features[0].map((feature, index) => {
            formData.append("features[" + index + "]", feature);
          });

          ApiRest({
            url: URL_UPDATE_PROMOTION,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          const response = await ApiRest({
            url: URL_UPDATE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            //cerrar modal
            Alerts({
              msg: "Alert",
              title: "Propuesta actualizada",
              code: "201",
              type: "success",
            });

            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };

            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 3,
            };

            dispatch(fetchProjects(params));

            return navigate(
              "/dashboard/project/" + null + "/" + response.data.data.id
            );
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "apply_template":
        {
          let formData = new FormData();
          formData.append("id", id);
          formData.append("title", form.title[0]);
          formData.append("price", form.price[0]);
          formData.append("time_lapse", form.time_lapse[0]);
          formData.append("methodology_id", form.methodology_id[0]);

          if (formPromotion.descont[0])
            formData.append(
              "promo_descont",
              parseFloat(formPromotion.descont[0])
            );
          if (formPromotion.time_duration[0])
            formData.append("time_duration", formPromotion.time_duration[0]);

          /* Payments */

          formData.append("title_option", form.title_option[0]);
          formData.append("description_option", form.description_option[0]);

          if (form.payment_name[0])
            formData.append("name_payment_types", form.payment_name[0]);

          if (form.payment_config[0])
            formData.append("config_payment_types", form.payment_config[0]);

          form.payment_types_id[0].map((payment, index) => {
            formData.append("payment_types_id[" + index + "]", payment);
          });

          /* features */
          form.features[0].map((feature, index) => {
            formData.append("features[" + index + "]", feature);
          });

          //faqs
          form.faqs[0].map((faq, index) => {
            formData.append("faqs[" + index + "]", faq);
          });

          ApiRest({
            url: URL_UPDATE_PROMOTION,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          const response = await ApiRest({
            url: URL_UPDATE_PROJECT,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            //cerrar modal
            Alerts({
              msg: "Alert",
              title: "Plantilla actualizada",
              code: "201",
              type: "success",
            });

            let params = {
              url: URL_INDEX_PROJECTS,
              status: 1,
            };
            dispatch(fetchProjects(params));
            params = {
              url: URL_INDEX_PROJECTS,
              status: 4,
            };
            dispatch(fetchProjects(params));

            return navigate("/dashboard/template/" + response.data.data.id);
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      /* Modals */
      case "save-service":
        {
          //console.log(value);

          let formData = new FormData();
          formData.append("description", value.description[0]);
          formData.append("duration_months", value.duration_months[0]);
          formData.append("image_url", value.image[0]);
          formData.append("name", value.name[0]);
          formData.append("price", value.price[0]);
          formData.append("subname", value.subname[0]);
          formData.append("unid", value.unid[0]);
          formData.append("unique", value.unique[0] ? 1 : 0);

          const response = await ApiRest({
            url: URL_CREATE_MSERVICE,
            formData: formData,
            setLoading: setLoading,
            method: "post",
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            setSteps(steps + 1);
            //cerrar modal
            return Alerts({
              msg: "Alert",
              title: "Model de servicio guardado",
              code: "201",
              type: "success",
            });
          }

          Object.keys(response.data).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data[key][0],
              code: "401",
              type: "error",
            });
          });
        }
        break;
      case "delete-service":
        {
          if (JSON.parse(id)) {
            let formData = new FormData();
            formData.append("id", form.services[0][value].id[0]);

            const response = await ApiRest({
              url: URL_DELETE_SERVICE,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              setSteps(steps + 1);
              //cerrar modal
              return Alerts({
                msg: "Alert",
                title: "Servicio eliminado",
                code: "201",
                type: "success",
              });
            }

            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
          }

          let setCampo = form;
          setCampo["services"][0].splice(value, 1);

          setForm({
            ...form,
            ["services"]: setCampo["services"],
          });
        }
        break;
      case "edit-service":
        {
          setSelect(value);
          setModalService(true);
        }
        break;
      case "delete-feature":
        {
          let setCampo = form;
          setCampo["features"][0].splice(
            setCampo["features"][0].indexOf(value),
            1
          );

          setForm({
            ...form,
            ["features"]: setCampo["features"],
          });
        }
        break;
      /* Features */
      case "add-faq":
        {
          let setCampo = form;
          let array = setCampo["faqs"][0];

          if (array.indexOf(value.id) < 0) {
            array.push(value.id);
          } else {
            array.splice(array.indexOf(value.id), 1);
          }

          setCampo["faqs"][0] = array;

          setForm({
            ...form,
            ["faqs"]: setCampo["faqs"],
          });
        }
        break;
      case "add-feature":
        {
          let setCampo = form;
          let array = setCampo["features"][0];

          if (array.indexOf(value.id) < 0) {
            array.push(value.id);
          } else {
            array.splice(array.indexOf(value.id), 1);
          }

          setCampo["features"][0] = array;

          setForm({
            ...form,
            ["features"]: setCampo["features"],
          });
        }
        break;
      /* Price */
      case "delete-price":
        {
          console.log(value);

          if (JSON.parse(id)) {
            let formData = new FormData();
            formData.append("id", value.id[0]);

            const response = await ApiRest({
              url: URL_DELETE_PRICE,
              formData: formData,
              setLoading: setLoading,
              method: "post",
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              setSteps(steps + 1);
              //cerrar modal
              return Alerts({
                msg: "Alert",
                title: "Paquete eliminado",
                code: "201",
                type: "success",
              });
            }

            Object.keys(response.data).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data[key][0],
                code: "401",
                type: "error",
              });
            });
          }

          let setCampo = form;
          setCampo["prices"][0].splice(value, 1);

          setForm({
            ...form,
            ["prices"]: setCampo["prices"],
          });
        }
        break;
      case "edit-price":
        {
          setSelect(value);
          setModalPrice(true);
        }
        break;
      case "add-price":
        {
          setSelect(null);
          setModalPrice(value);
        }
        break;
      /* FAQs */
      case "btn-accordion":
        {
          if (
            document
              .getElementById("flush-content-" + value)
              .classList.contains("show")
          ) {
            document
              .getElementById("collapsed-" + value)
              .classList.add("collapsed");
            document
              .getElementById("flush-content-" + value)
              .classList.remove("show");
          } else {
            document
              .getElementById("collapsed-" + value)
              .classList.remove("collapsed");
            document
              .getElementById("flush-content-" + value)
              .classList.add("show");
          }
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    e.preventDefault();
    console.log(e);

    switch (value.target.name) {
      case "drag":
        {
          setDrag(value);
        }
        break;
      case "drop":
        {
          let features = orderFeatures;

          console.log(value);

          if (value.target.parentElement.id !== "" && drag) {
            let element = value.target.parentElement.id.split(" ");
            let _id = element[4].split("-")[1];
            let _index = element[2].split("-")[1];
            let _position = element[3].split("-")[1];
            let _type = element[0].split("-")[1];

            //console.log(_index);
            //console.log(drag);

            /* Order feature */

            features[drag.type_index].features[drag.index].pivot[0].position =
              _index;
            features[drag.type_index].features[_index].pivot[0].position =
              drag.index;

            setOrderFeatures([...features]);

            /* */

            //features[drag.type_index].features[drag.index].pivot[0].position =_index;
            //features[drag.type_index].features[_index].pivot[0].position = drag.index;

            //const elements = document.querySelectorAll(".type-" + drag.type);
            //let setPosition = parseInt(_index) + 1;
          }
        }
        break;
    }
  };

  useEffect(() => {
    if (!payment_types) {
      let params = {
        url: URL_INDEX_PAYMENTS,
      };

      dispatch(fetchPayments(params));
    }
  }, []);

  useEffect(() => {
    if (!methodologies) {
      let params = {
        url: URL_INDEX_METHODOLOGIES,
      };

      dispatch(fetchMethodologies(params));
    }
  }, []);

  useEffect(() => {
    if (!features_types) {
      let params = {
        url: URL_INDEX_FEATURES_TYPES,
      };

      dispatch(fetchFeaturesTypes(params));
    }
  }, []);

  useEffect(() => {
    if (!regions) {
      let params = {
        url: URL_INDEX_REGIONS,
      };

      dispatch(fetchRegions(params));
    }
  }, [regions]);

  useEffect(() => {
    if (!faqs) {
      let params = {
        url: URL_INDEX_FAQS,
        status: 1,
        pagination: 50,
      };
      dispatch(fetchFaqsProject(params));
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (JSON.parse(id) && features_types) {
        setLoadingScreen(true);

        let params = {
          id: id,
        };
        const response = await clientAxios.get(URL_INDEX_PROJECTS, { params });

        if (response.status == 200) {
          let proyect = AttachProject(response.data, features_types);
          setForm(JSON.parse(JSON.stringify(proyect)));

          /* Order feature */
          let result = [];
          features_types.map((f) => {
            let fea = [];

            f.features.map((e, index) => {
              let pivot = [];

              e = {
                ...e,
                pivot:
                  pivot.length > 0
                    ? pivot[0].position == 100
                      ? [
                          {
                            position: index,
                          },
                        ]
                      : pivot
                    : [
                        {
                          position: index,
                        },
                      ],
              };

              fea.push(e);
            });

            f = {
              ...f,
              features: fea,
            };

            result.push(f);
          });

          setOrderFeatures(JSON.parse(JSON.stringify(result)));

          if (response.data.clients) {
            setFormClient(
              JSON.parse(JSON.stringify(AttachClient(response.data.clients)))
            );
          } else {
            setFormClient(formClient);
          }

          if (response.data.promotions.length > 0) {
            setFormPromotion(
              JSON.parse(
                JSON.stringify(AttachPromotion(response.data.promotions[0]))
              )
            );
          }

          setLoadingScreen(false);
        }
      }
    };

    fetch();
  }, [JSON.parse(id), steps, features_types]);

  useEffect(() => {
    if (!JSON.parse(id) && features_types && faqs) {
      //setLoadingGuard(false);
      //setForm();
      setFormClient(JSON.parse(JSON.stringify(FORM_CLIENTS)));
      setFormPromotion(JSON.parse(JSON.stringify(FORM_PROMOTIONS)));

      let setCampo = form;
      let array = [];

      features_types.map((type) => {
        type.features.map((feat) => {
          //array.push(feat.id);
        });
      });

      setCampo["features"][0] = array;

      let array2 = [];
      faqs.data.map((faq) => {
        array2.push(faq.id);
      });

      setCampo["faqs"][0] = array2;

      let result = [];
      features_types.map((f) => {
        let fea = [];

        f.features.map((e, index) => {
          let pivot = [];

          e = {
            ...e,
            pivot:
              pivot.length > 0
                ? pivot[0].position == 100
                  ? [
                      {
                        position: index,
                      },
                    ]
                  : pivot
                : [
                    {
                      position: index,
                    },
                  ],
          };

          fea.push(e);
        });

        f = {
          ...f,
          features: fea,
        };

        result.push(f);
      });

      setOrderFeatures(result);

      setForm({
        ...JSON.parse(JSON.stringify(FORM_PROJECT)),
        ["features"]: setCampo["features"],
        ["faqs"]: setCampo["faqs"],
      });
    }
  }, [id, steps, features_types]);

  console.log({ form });
  console.log(JSON.parse(id));

  //console.log(id);
  //console.log(formClient);
  //console.log(type);
  //console.log(formPromotion);
  //console.log(faqs);
  //console.log(form);

  if (id && loadingScreen) {
    return (
      <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
        <Spinner text={"Precargando proyecto"} />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {payment_types && methodologies && features_types && faqs ? (
        <div>
          <div className="row mb-2">
            <div className="col-md-6 mb-2">
              <h4>
                {type == "project"
                  ? form.title[0]
                  : type == "template"
                  ? "PLANTILLA " + form.template[0]
                  : type == "new"
                  ? "NUEVA PROPUESTA"
                  : ""}
              </h4>
            </div>
            <FormRegion
              formClient={formClient}
              setFormClient={setFormClient}
              validate={validateClient.regions_id}
              regions={regions ? regions : []}
              onClick={onClick}
            />
          </div>
          <div className="row mb-4">
            <div className="col-md-6 mb-4 mb-md-0">
              <FormGeneral
                type={type}
                form={form}
                setForm={setForm}
                formClient={formClient}
                setFormClient={setFormClient}
                validate={validate}
                validateClient={validateClient}
                id={JSON.parse(id)}
                template={form.template[0] ? form.template[0] : ""}
                data={{
                  clients: clients,
                }}
                urls={{
                  url_index_clients: URL_INDEX_CLIENT,
                }}
              />

              <FormPayments
                data={payment_types ? payment_types : []}
                form={form}
                setForm={setForm}
                validate={validate}
                payment_config={PAYMENT_CONFIG}
              />

              <div className="row">
                <div className="col-md-6 mb-4">
                  {form.price_basico[0] ? (
                    <CardPrice
                      onClick={onClick}
                      price={form.price_basico[0]}
                      premium={0}
                      data={payment_types ? payment_types : []}
                    />
                  ) : (
                    <div className="card-conteiner h-100">
                      <h4 className="mb-4">Paquete Básico</h4>

                      <div className="d-grid gap-2">
                        <label className="btn btn-primary text-center">
                          AGREGAR
                          <button
                            className="d-none"
                            name="add-price"
                            onClick={(event) => onClick(event, "price_basico")}
                          ></button>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  {form.price_premium[0] ? (
                    <CardPrice
                      onClick={onClick}
                      price={form.price_premium[0]}
                      premium={0}
                      data={payment_types ? payment_types : []}
                    />
                  ) : (
                    <div className="card-conteiner h-100">
                      <h4 className="mb-4">Paquete Premium</h4>

                      <div className="d-grid gap-2">
                        <label className="btn btn-primary text-center">
                          AGREGAR
                          <button
                            className="d-none"
                            name="add-price"
                            onClick={(event) => onClick(event, "price_premium")}
                          ></button>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <FormPromotion
                form={formPromotion}
                setForm={setFormPromotion}
                validate={validatePromotion}
              />

              <FormMethodology
                data={methodologies ? methodologies : []}
                form={form}
                setForm={setForm}
                validate={validate.methodology_id}
              />
            </div>
            <div className="col-md-6">
              <div className="card-conteiner mb-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="m-0">Servicios opcionales</h4>
                  <div className="d-flex">
                    <label className="btn btn-primary d-flex me-1">
                      <img src="/assets/img/icons/icon-plus.svg" className="" />
                      <button
                        className="d-none"
                        name="modalModelService"
                        onClick={(event) => setModalModelService(true)}
                      ></button>
                    </label>
                    <label className="btn btn-primary d-flex">
                      <img
                        src="/assets/img/icons/icon-plus.svg"
                        className="me-2"
                      />
                      AÑADIR SERVICIO
                      <button
                        className="d-none"
                        name="modalService"
                        onClick={(event) => setModalService(true)}
                      ></button>
                    </label>
                  </div>
                </div>

                {form.services[0].map((service, index) => (
                  <CardService
                    index={index}
                    service={service}
                    onClick={onClick}
                  />
                ))}
              </div>

              <div className="card-conteiner mb-3">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h4 className="m-0">FAQs</h4>
                  <label className="btn btn-primary">
                    <img src="/assets/img/icons/icon-edit.svg" />
                    <button
                      className="d-none"
                      name="modalService"
                      onClick={(event) => setModalFaqs(true)}
                    ></button>
                  </label>
                </div>
                {faqs
                  ? faqs.data.map((faq, index) => (
                      <div
                        className="card-services p-1 mb-3"
                        style={{ borderRadius: "10px" }}
                      >
                        {form.faqs[0].indexOf(faq.id) >= 0 ? (
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item">
                              <div className="accordion-button collapsed">
                                <label className="btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-check-circle-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                  <button
                                    className="d-none"
                                    name="add-faq"
                                    onClick={(event) => onClick(event, faq)}
                                  ></button>
                                </label>
                                <label>
                                  <h4
                                    className="accordion-header"
                                    id="flush-headingOne"
                                  >
                                    {faq.question}
                                  </h4>
                                  <button
                                    className="d-none"
                                    type="button"
                                    id={"collapsed-" + index}
                                    name="btn-accordion"
                                    onClick={(event) => onClick(event, index)}
                                  ></button>
                                </label>
                              </div>
                              <div
                                id={"flush-content-" + index}
                                className="accordion-collapse collapse"
                              >
                                <div
                                  className="accordion-body p-3"
                                  dangerouslySetInnerHTML={{
                                    __html: faq.response,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item">
                              <div className="accordion-button collapsed">
                                <label className="btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-check-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                  </svg>
                                  <button
                                    className="d-none"
                                    name="add-faq"
                                    onClick={(event) => onClick(event, faq)}
                                  ></button>
                                </label>
                                <label>
                                  <h4
                                    className="accordion-header"
                                    id="flush-headingOne"
                                  >
                                    {faq.question}
                                  </h4>
                                  <button
                                    className="d-none"
                                    type="button"
                                    id={"collapsed-" + index}
                                    name="btn-accordion"
                                    onClick={(event) => onClick(event, index)}
                                  ></button>
                                </label>
                              </div>
                              <div
                                id={"flush-content-" + index}
                                className="accordion-collapse collapse "
                              >
                                <div
                                  className="accordion-body p-3"
                                  dangerouslySetInnerHTML={{
                                    __html: faq.response,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  : ""}

                <div className="col-md-12 ">
                  <Pagination
                    pagination={{ ...faqs.pagination, type: 2 }}
                    onClick={onClick}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card-conteiner mb-4">
                <div className="mb-4">
                  <h4 className="m-0">Características</h4>
                </div>
                <div className="row">
                  {orderFeatures
                    ? orderFeatures.map((type, _index) => (
                        <div className="col-md-6  mb-3">
                          <div className="card-services">
                            <h4>{type.name}</h4>

                            {type.features.map((feat, index) => (
                              <>
                                {form.features[0].indexOf(feat.id) >= 0 ? (
                                  <label
                                    className={
                                      "btn w-100 border-0" +
                                      " type-" +
                                      type.name
                                    }
                                    draggable="true"
                                    onDragEnd={(event) =>
                                      onChange(event, {
                                        target: {
                                          name: "drag",
                                        },
                                        type: type.name,
                                        type_index: _index,
                                        data: feat,
                                        index: index,
                                      })
                                    }
                                    onDragLeave={(event) =>
                                      onChange(
                                        event,
                                        {
                                          target: {
                                            name: "drop",
                                            parentElement: {
                                              id: event.target.parentElement.id,
                                            },
                                          },
                                        },
                                        feat
                                      )
                                    }
                                    id={
                                      "type-" +
                                      type.name +
                                      " name-" +
                                      feat.description +
                                      " item-" +
                                      index +
                                      " position-" +
                                      feat.pivot[0].position +
                                      " id-" +
                                      feat.id +
                                      " type-" +
                                      type.id
                                    }
                                  >
                                    <div
                                      className="w-100 mt-4 d-flex justify-content-between align-items-center"
                                      id={
                                        "type-" +
                                        type.name +
                                        " name-" +
                                        feat.description +
                                        " item-" +
                                        index +
                                        " position-" +
                                        feat.pivot[0].position +
                                        " id-" +
                                        feat.id +
                                        " type-" +
                                        type.id
                                      }
                                    >
                                      <div
                                        id={
                                          "type-" +
                                          type.name +
                                          " name-" +
                                          feat.description +
                                          " item-" +
                                          index +
                                          " position-" +
                                          feat.pivot[0].position +
                                          " id-" +
                                          feat.id +
                                          " type-" +
                                          type.id
                                        }
                                        style={{
                                          maxHeight: "150px",
                                          textOverflow: "ellipsis",
                                        }}
                                        className="w-100 overflow-auto"
                                        dangerouslySetInnerHTML={{
                                          __html: feat.description,
                                        }}
                                      ></div>
                                      <div>
                                        <div className="btn border-0 fs-4">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="currentColor"
                                            className="bi bi-check-circle-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                          </svg>
                                        </div>
                                        <button
                                          className="d-none"
                                          name="add-feature"
                                          onClick={(event) =>
                                            onClick(event, feat)
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                    <div
                                      className="p-0 m-0 w-100 border border-0"
                                      style={{ height: "0px" }}
                                      id={"item-position-bottom-" + index}
                                    ></div>
                                  </label>
                                ) : (
                                  <label className="btn border-0 w-100">
                                    <div className="w-100 mt-4 d-flex justify-content-between align-items-center">
                                      <div
                                        style={{
                                          maxHeight: "150px",
                                          textOverflow: "ellipsis",
                                        }}
                                        className=" w-100 overflow-auto"
                                        dangerouslySetInnerHTML={{
                                          __html: feat.description,
                                        }}
                                      ></div>
                                      <div>
                                        <div className="btn border-0 fs-4">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="currentColor"
                                            className="bi bi-check-circle"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                          </svg>
                                        </div>
                                        <button
                                          className="d-none"
                                          name="add-feature"
                                          onClick={(event) =>
                                            onClick(event, feat)
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </label>
                                )}
                              </>
                            ))}
                            <label className="btn btn-primary d-flex mt-3">
                              <svg
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-2"
                              >
                                <path
                                  d="M0.686535 11.8045C0.686535 12.2762 0.855643 12.6943 1.19386 13.0588C1.53208 13.4233 1.92005 13.6056 2.35774 13.6056H9.28132L9.28131 21.1313C9.28131 21.603 9.45082 22.0103 9.78984 22.3534C10.1273 22.6964 10.5148 22.868 10.9525 22.868C11.3902 22.868 11.7782 22.6857 12.1164 22.3212C12.4546 21.9567 12.6237 21.5386 12.6237 21.0669V13.6056L19.607 13.6056C20.0447 13.6056 20.4227 13.4229 20.741 13.0575C21.0593 12.6939 21.2185 12.2762 21.2185 11.8045C21.2185 11.3328 21.0494 10.9147 20.7112 10.5503C20.373 10.1858 19.985 10.0035 19.5473 10.0035L12.6237 10.0035L12.6237 2.47781C12.6237 2.00611 12.455 1.59874 12.1176 1.25568C11.7786 0.912632 11.3902 0.741106 10.9525 0.741106C10.5148 0.741106 10.1269 0.923353 9.78865 1.28785C9.45042 1.65234 9.28131 2.07043 9.28131 2.54213L9.28132 10.0035L2.29806 10.0035C1.86036 10.0035 1.48274 10.1858 1.16522 10.5503C0.846094 10.9148 0.686535 11.3328 0.686535 11.8045Z"
                                  fill="white"
                                />
                              </svg>
                              CARACTERÍSTICA
                              <button
                                className="d-none"
                                name=""
                                onClick={() => setModalFeature(type)}
                              ></button>
                            </label>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-4">
              <div className="card-conteiner text-center h-100">
                <h3 className="mb-4">¿Listo?</h3>
                <div className="row">
                  <div className="col-md-4 d-grid mb-3">
                    {type == "template" ? (
                      <label
                        className={
                          loading
                            ? "btn btn-primary disabled"
                            : "btn btn-primary"
                        }
                      >
                        Guardar plantilla
                        <button
                          href="#"
                          className="d-none"
                          name="apply_template"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    ) : (
                      <label
                        className={
                          loading
                            ? "btn btn-primary disabled"
                            : "btn btn-primary"
                        }
                      >
                        Guardar como plantilla
                        <button
                          href="#"
                          className="d-none"
                          name="save_template"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )}
                  </div>
                  <div className="col-md-4 d-grid mb-3">
                    {JSON.parse(id) ? (
                      <label
                        className={
                          loading
                            ? "btn btn-primary disabled"
                            : "btn btn-primary"
                        }
                      >
                        Guardar como borrador
                        <button
                          href="#"
                          className="d-none"
                          name="apply_eraser"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    ) : (
                      <label
                        className={
                          loading
                            ? "btn btn-primary disabled"
                            : "btn btn-primary"
                        }
                      >
                        Guardar como borrador
                        <button
                          href="#"
                          className="d-none"
                          name="save_eraser"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )}
                  </div>
                  <div className="col-md-4 d-grid mb-3">
                    <label
                      className={
                        loading ? "btn btn-primary disabled" : "btn btn-primary"
                      }
                    >
                      Previsualizar
                      <button
                        className="d-none"
                        name="preview"
                        onClick={(event) => onClick(event)}
                      ></button>
                    </label>
                  </div>
                  <div className="col-12 d-grid">
                    {type == "new" ? (
                      <label
                        className={
                          loading
                            ? "btn btn-success disabled"
                            : "btn btn-success"
                        }
                      >
                        {loading ? (
                          <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "FINALIZAR"
                        )}
                        <button
                          className="d-none"
                          name="save"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    ) : type == "project" ? (
                      <label
                        className={
                          loading
                            ? "btn btn-success disabled"
                            : "btn btn-success"
                        }
                      >
                        {loading ? (
                          <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "APLICAR CAMBIOS"
                        )}
                        <button
                          className="d-none"
                          name="apply"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    ) : type == "template" ? (
                      <label
                        className={
                          loading
                            ? "btn btn-success disabled"
                            : "btn btn-success"
                        }
                      >
                        {loading ? (
                          <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "FINALIZAR"
                        )}
                        <button
                          className="d-none"
                          name="save"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {modalModelService ? (
            <ModalListServices
              setModal={setModalModelService}
              form={form}
              setForm={setForm}
              validate={validate}
              setValidate={setValidate}
              setSteps={setSteps}
              steps={steps}
              setSelect={setSelect}
              select={select}
              loading={loading}
              setLoading={setLoading}
              id={JSON.parse(id)}
            />
          ) : (
            ""
          )}

          {modalService ? (
            <ModalService
              setModal={setModalService}
              form={form}
              setForm={setForm}
              validate={validate}
              setValidate={setValidate}
              setSteps={setSteps}
              steps={steps}
              setSelect={setSelect}
              select={select}
              loading={loading}
              setLoading={setLoading}
              id={JSON.parse(id)}
            />
          ) : (
            ""
          )}

          {modalPrice ? (
            <ModalPrice
              modal={modalPrice}
              setModal={setModalPrice}
              form={form}
              setForm={setForm}
              validate={validate}
              setValidate={setValidate}
              setSelect={setSelect}
              select={select}
              setSteps={setSteps}
              steps={steps}
              loading={loading}
              setLoading={setLoading}
              id={JSON.parse(id)}
              data={payment_types ? payment_types : []}
              payment_config={PAYMENT_CONFIG}
            />
          ) : (
            ""
          )}

          {modalFeature ? (
            <ModalFeature
              modal={modalFeature}
              setModal={setModalFeature}
              form={form}
              setForm={setForm}
              validate={validate}
              setValidate={setValidate}
              setSelect={setSelect}
              select={select}
              loading={loading}
              setLoading={setLoading}
              id={JSON.parse(id)}
            />
          ) : (
            ""
          )}

          {modalFaqs ? (
            <ModalFaqs
              modal={modalFaqs}
              setModal={setModalFaqs}
              form={form}
              setForm={setForm}
              validate={validate}
              setValidate={setValidate}
              setSelect={setSelect}
              select={select}
              loading={loading}
              setLoading={setLoading}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
          <Spinner
            text={
              !payment_types
                ? "Cargando tipos de pagos..."
                : !methodologies
                ? "Cargando metodologias..."
                : !features_types
                ? "Cargando caracteristicas..."
                : ""
            }
          />
        </div>
      )}
    </div>
  );
};

export default Project;
